<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header card-header-sm-stretch">
          <h3 class="card-title">Captura de Empleado</h3>
        </div>
        <div class="card-body">
          <ul class="nav nav-tabs nav-line-tabs">
            <li class="nav-item">
              <a
                class="nav-link active"
                data-bs-toggle="tab"
                href="#kt_tab_pane_1"
                >Expediente</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_2"
                >Documentos</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_3"
                >Psicometrico</a
              >
            </li>
          </ul>
          <div class="tab-content">
            <div
              class="tab-pane fade active show"
              id="kt_tab_pane_1"
              role="tabpanel"
            >
              <div class="row">
                <div class="col-md-8">
                  <div class="card card-bordered mt-3">
                    <div class="card-header">
                      <div class="card-title-form">Datos Generales</div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-floating mb-7">
                            <input
                              type="text"
                              class="form-control required"
                              id="nombre"
                              v-model="forma.nombre"
                            />
                            <label for="nombre">Nombre(s)</label>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-floating mb-7">
                            <input
                              type="text"
                              class="form-control required"
                              id="paterno"
                              v-model="forma.paterno"
                            />
                            <label for="paterno">Apellido Paterno</label>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-floating mb-7">
                            <input
                              type="text"
                              class="form-control"
                              id="materno"
                              v-model="forma.materno"
                            />
                            <label for="paterno">Apellido Materno</label>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-floating mb-7">
                            <input
                              type="date"
                              class="form-control"
                              id="fecha_nacimiento"
                              v-model="forma.fecha_nacimiento"
                            />
                            <label for="fecha_nacimiento"
                              >Fec. Nacimiento</label
                            >
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-floating mb-7">
                            <select
                              class="form-select"
                              id="sexo"
                              v-model="forma.genero_id"
                            >
                              <option value="0">:: Seleccionar ::</option>
                              <option
                                v-for="genero in generos"
                                :key="genero.id"
                                :value="genero.id"
                              >
                                {{ genero.genero }}
                              </option>
                            </select>
                            <label for="sexo">Sexo</label>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-floating mb-7">
                            <input
                              type="text"
                              class="form-control"
                              id="telefono"
                              v-model="forma.telefono"
                            />
                            <label for="telefono">Teléfono</label>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="form-floating mb-7">
                            <input
                              type="text"
                              class="form-control"
                              id="domicilio"
                              v-model="forma.domicilio"
                            />
                            <label for="domicilio">Domicilio</label>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-floating mb-7">
                            <input
                              type="text"
                              class="form-control"
                              id="lugar_nacimiento"
                              v-model="forma.lugar_nacimiento"
                            />
                            <label for="lugar_nacimiento"
                              >Lugar de Nacimiento</label
                            >
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-floating mb-7">
                            <select
                              class="form-select"
                              id="vive_con"
                              v-model="forma.vive_con_id"
                            >
                              <option value="0">:: Seleccionar ::</option>
                              <option
                                v-for="viveitem in vive"
                                :key="viveitem.id"
                                :value="viveitem.id"
                              >
                                {{ viveitem.vive_con }}
                              </option>
                            </select>
                            <label for="vive_con">Vive con</label>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-floating mb-7">
                            <input
                              type="text"
                              class="form-control"
                              id="estatura"
                              v-model="forma.estatura"
                            />
                            <label for="estatura">Estatura</label>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-floating mb-7">
                            <input
                              type="text"
                              class="form-control"
                              id="peso"
                              v-model="forma.peso"
                            />
                            <label for="peso">Peso</label>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-floating mb-7">
                            <select
                              class="form-select"
                              id="estado_civil"
                              v-model="forma.civil_id"
                            >
                              <option value="0">:: Seleccionar ::</option>
                              <option
                                v-for="civil in civiles"
                                :key="civil.id"
                                :value="civil.id"
                              >
                                {{ civil.civil }}
                              </option>
                            </select>
                            <label for="estado_civil">Estado Civil</label>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-floating mb-7">
                            <select
                              class="form-select"
                              id="hijos"
                              v-model="forma.hijos"
                            >
                              <option value="1">Si</option>
                              <option value="0">No</option>
                            </select>
                            <label for="hijos">Tiene Hijos</label>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-floating mb-7">
                            <input
                              type="text"
                              class="form-control"
                              id="hijos_cuantos"
                              v-model="forma.hijos_cuantos"
                            />
                            <label for="hijos_cuantos">Cuantos Hijos</label>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-floating mb-7">
                            <select
                              class="form-select"
                              id="puesto"
                              v-model="forma.puesto_id"
                            >
                              <option value="0">:: Seleccionar ::</option>
                              <option
                                v-for="puesto in puestos"
                                :key="puesto.id"
                                :value="puesto.id"
                              >
                                {{ puesto.puesto }}
                              </option>
                            </select>
                            <label for="puesto">Puesto al que aspira</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card card-bordered mt-3">
                    <div class="card-header">
                      <div class="card-title-form">Estado de Salud</div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-floating mb-7">
                            <select
                              class="form-select"
                              id="cronica"
                              v-model="forma.cronica"
                            >
                              <option value="1">Si</option>
                              <option value="0">No</option>
                            </select>
                            <label for="cronica"
                              >¿Padece alguna enfermedad cronica?</label
                            >
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="form-floating mb-7">
                            <input
                              type="text"
                              class="form-control"
                              id="cronica_descripcion"
                              v-model="forma.cronica_descripcion"
                            />
                            <label for="cronica_descripcion"
                              >Describa su enfermedad</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card card-bordered mt-3">
                    <div class="card-header">
                      <div class="card-title-form">Referencias Personales</div>
                      <div class="card-toolbar">
                        <button
                          type="button"
                          class="btn btn-sm btn-light-dark"
                          @click="handleAgregarRefPer"
                        >
                          Agregar referencia personal
                        </button>
                      </div>
                    </div>
                    <div class="card-body">
                      <table class="table table-sm table-bordered g-2">
                        <thead>
                          <tr class="table-active border">
                            <th>Nombre</th>
                            <th>Teléfono</th>
                            <th>Domicilio</th>
                            <th>Parentesco</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="border"
                            v-for="item in forma.referenciasPersonales"
                            :key="item.id"
                          >
                            <td>{{ item.nombre }}</td>
                            <td>{{ item.telefono }}</td>
                            <td>{{ item.domicilio }}</td>
                            <td>{{ item.parentesco }}</td>
                            <td class="text-center">
                              <button
                                type="button"
                                class="btn btn-sm btn-xs btn-light-primary mx-2"
                                @click="
                                  () => {
                                    handleEditarReferenciaPersonal(item.id);
                                  }
                                "
                              >
                                <i class="bi bi-pencil"></i>
                              </button>

                              <button
                                type="button"
                                class="btn btn-sm btn-xs btn-light-danger"
                                @click="
                                  () => {
                                    handleQuitarReferenciaPersonal(item.id);
                                  }
                                "
                              >
                                <i class="bi bi-trash"></i>
                              </button>
                            </td>
                          </tr>
                          <tr class="border"></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="card card-bordered mt-3">
                    <div class="card-header">
                      <div class="card-title-form">Referencias Laborales</div>
                      <div class="card-toolbar">
                        <button
                          type="button"
                          class="btn btn-sm btn-light-dark"
                          @click="handleAgregarRefLab"
                        >
                          Agregar referencia laboral
                        </button>
                      </div>
                    </div>
                    <div class="card-body">
                      <table class="table table-sm table-bordered g-2">
                        <thead>
                          <tr class="table-active border">
                            <th>Nombre</th>
                            <th>Teléfono</th>
                            <th>Domicilio</th>
                            <th>Empresa</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="border"
                            v-for="item in forma.referenciasLaborales"
                            :key="item.id"
                          >
                            <td>{{ item.nombre }}</td>
                            <td>{{ item.telefono }}</td>
                            <td>{{ item.domicilio }}</td>
                            <td>{{ item.empresa }}</td>
                            <td class="text-center">
                              <button
                                type="button"
                                class="btn btn-sm btn-xs btn-light-primary mx-2"
                                @click="
                                  () => {
                                    handleEditarReferenciaLaboral(item.id);
                                  }
                                "
                              >
                                <i class="bi bi-pencil"></i>
                              </button>

                              <button
                                type="button"
                                class="btn btn-sm btn-xs btn-light-danger"
                                @click="
                                  () => {
                                    handleQuitarReferenciaLaboral(item.id);
                                  }
                                "
                              >
                                <i class="bi bi-trash"></i>
                              </button>
                            </td>
                          </tr>
                          <tr class="border"></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="card card-bordered mt-3">
                    <div class="card-header">
                      <div class="card-title-form">Escolaridad</div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-floating mb-7">
                            <select
                              class="form-select"
                              id="estudios"
                              v-model="forma.escolaridad_id"
                            >
                              <option value="0">:: Seleccionar ::</option>
                              <option
                                v-for="esc in escolaridades"
                                :key="esc.id"
                                :value="esc.id"
                              >
                                {{ esc.escolaridad }}
                              </option>
                            </select>
                            <label for="estudios"
                              >¿Nivel maximo de estudios?</label
                            >
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-floating mb-7">
                            <input
                              type="text"
                              class="form-control"
                              id="estudios_grado"
                              v-model="forma.escolaridad_grado"
                            />
                            <label for="estudios_grado">Grado alcanzado</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card card-bordered mt-3">
                    <div class="card-header">
                      <div class="card-title-form">
                        Experiencia Laboral como guardia
                      </div>
                      <div class="card-toolbar">
                        <button
                          type="button"
                          class="btn btn-sm btn-light-dark"
                          @click="handleAgregarExp"
                        >
                          Agregar experiencia laboral
                        </button>
                      </div>
                    </div>
                    <div class="card-body">
                      <table class="table table-sm table-bordered g-2">
                        <thead>
                          <tr class="table-active border">
                            <th>Empresa</th>
                            <th>Tiempo laborado</th>
                            <th>Actividades Realizadas</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="border"
                            v-for="item in forma.experiencia"
                            :key="item.id"
                          >
                            <td>{{ item.empresa }}</td>
                            <td>{{ item.tiempo }}</td>
                            <td>{{ item.actividades }}</td>
                            <td class="text-center">
                              <button
                                type="button"
                                class="btn btn-sm btn-xs btn-light-primary mx-2"
                                @click="
                                  () => {
                                    handleEditarExperiencia(item.id);
                                  }
                                "
                              >
                                <i class="bi bi-pencil"></i>
                              </button>

                              <button
                                type="button"
                                class="btn btn-sm btn-xs btn-light-danger"
                                @click="
                                  () => {
                                    handleQuitarExperiencia(item.id);
                                  }
                                "
                              >
                                <i class="bi bi-trash"></i>
                              </button>
                            </td>
                          </tr>
                          <tr class="border"></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="card card-bordered mt-3">
                    <div class="card-header">
                      <div class="card-title-form">Otros Datos</div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-floating mb-7">
                            <select
                              class="form-select"
                              id="medio"
                              v-model="forma.medio_id"
                            >
                              <option value="0">:: Seleccionar ::</option>
                              <option
                                v-for="medio in medios"
                                :key="medio.id"
                                :value="medio.id"
                              >
                                {{ medio.medio }}
                              </option>
                            </select>
                            <label for="medio"
                              >Medio por el cual se enteró de la vacante</label
                            >
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-floating mb-7">
                            <select
                              class="form-select"
                              id="contratante"
                              v-model="forma.ejecutivo_id"
                            >
                              <option value="0">:: Seleccionar ::</option>
                              <option
                                v-for="ejecutivo in ejecutivos"
                                :key="ejecutivo.id"
                                :value="ejecutivo.id"
                              >
                                {{ ejecutivo.name }}
                              </option>
                            </select>
                            <label for="contratante"
                              >Ejecutivo que contrata</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="card card-bordered mt-3">
                    <div class="card-header">
                      <div class="card-title-form">Estatus Actual</div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12 text-center">
                          <div
                            class="
                              image-input
                              image-input-empty
                              image-input-placeholder
                            "
                            data-kt-image-input="true"
                            :style="{ backgroundImage: `url(${empFoto})` }"
                          >
                            <div
                              class="image-input-wrapper w-125px h-125px"
                            ></div>

                            <label
                              class="
                                btn
                                btn-icon
                                btn-circle
                                btn-color-muted
                                btn-active-color-primary
                                w-25px
                                h-25px
                                bg-body
                                shadow
                              "
                              data-kt-image-input-action="change"
                              data-bs-toggle="tooltip"
                              data-bs-dismiss="click"
                              title="Cambiar fotografia"
                            >
                              <i class="bi bi-pencil-fill fs-7"></i>

                              <input
                                type="file"
                                name="avatar"
                                accept=".jpg"
                                @change="handleChangeFoto"
                              />
                              <input type="hidden" name="avatar_remove" />
                            </label>

                            <span
                              class="
                                btn
                                btn-icon
                                btn-circle
                                btn-color-muted
                                btn-active-color-primary
                                w-25px
                                h-25px
                                bg-body
                                shadow
                              "
                              data-kt-image-input-action="cancel"
                              data-bs-toggle="tooltip"
                              data-bs-dismiss="click"
                              title="Cancel avatar"
                            >
                              <i class="bi bi-x fs-2"></i>
                            </span>

                            <span
                              class="
                                btn
                                btn-icon
                                btn-circle
                                btn-color-muted
                                btn-active-color-primary
                                w-25px
                                h-25px
                                bg-body
                                shadow
                              "
                              data-kt-image-input-action="remove"
                              data-bs-toggle="tooltip"
                              data-bs-dismiss="click"
                              title="Remove avatar"
                            >
                              <i class="bi bi-x fs-2"></i>
                            </span>
                          </div>
                        </div>
                        <div class="col-md-12 mt-3 text-center">
                          Estatus:
                          <span class="badge badge-primary badge-sm">New</span>
                        </div>
                        <div class="col-md-12 mt-3 text-center">Proyecto:</div>
                        <div class="col-md-12 mt-3 text-center">Horario:</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
              <div class="table-responsive">
                <table class="table table-sm table-bordered g-2">
                  <thead>
                    <tr class="table-active border">
                      <th>Documento</th>
                      <th>Fecha</th>
                      <th>Subir</th>
                      <th>Ver</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="doc in documentos"
                      :key="doc.id + renderDoc"
                      class="border"
                    >
                      <td>{{ doc.documento }}</td>
                      <td></td>
                      <th>
                        <button
                          class="btn btn-sm btn-xs btn-light-primary"
                          type="button"
                          @click="
                            () => {
                              handleSubirDocumento(doc.id);
                            }
                          "
                        >
                          <i class="bi bi-upload"></i> Subir documento
                        </button>
                        <small
                          class="mx-2"
                          v-for="docSel in listaDocumentos.filter(
                            (item) => item.documento_id === doc.id
                          )"
                          :key="docSel.documento_id"
                          >{{ docSel.nombre }}</small
                        >
                      </th>
                      <th>
                        <template
                          v-for="docSel in listaDocumentos.filter(
                            (item) =>
                              item.documento_id === doc.id &&
                              item.link.length > 0
                          )"
                          :key="docSel.documento_id"
                        >
                          <a
                            :href="docSel.link"
                            target="_blank"
                            class="btn btn-sm btn-xs btn-light-primary"
                          >
                            <i class="bi bi-download"></i>
                          </a>
                        </template>
                      </th>
                    </tr>
                  </tbody>
                </table>
                <input
                  type="file"
                  name="documento"
                  id="documento"
                  class="d-none"
                  @change="handleCambiaDocumento"
                />
              </div>
            </div>

            <div class="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
              <div class="row mt-3">
                <div
                  class="col-md-12"
                  v-for="quest in cuestionario"
                  :key="quest.id"
                >
                  <div class="card card-bordered mt-3">
                    <div class="card-header">
                      <div class="card-title-form">{{ quest.pregunta }}</div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6">
                          <template v-for="opc in quest.opciones" :key="opc.id">
                            <input
                              type="radio"
                              :name="`pregunta${quest.id}`"
                              id=""
                              v-model="forma.respuestas[quest.id]"
                              :value="opc.id"
                            />
                            {{ opc.opcion }} <br />
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-md-12">
                  <div class="card card-bordered mt-3">
                    <div class="card-header">
                      <div class="card-title-form">Evaluación</div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-floating mb-7">
                            <select
                              class="form-select"
                              id="personalidad"
                              v-model="forma.personalidad"
                            >
                              <option value="0">:: Seleccionar ::</option>
                              <option
                                v-for="per in personalidad"
                                :key="per.id"
                                :value="per.id"
                              >
                                {{ per.respuesta }}
                              </option>
                            </select>
                            <label for="personalidad"
                              >Diagnostico de Personalidad</label
                            >
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-floating mb-7">
                            <select
                              class="form-select"
                              id="aprendizaje"
                              v-model="forma.aprendizaje"
                            >
                              <option value="0">:: Seleccionar ::</option>
                              <option
                                v-for="per in aprendizaje"
                                :key="per.id"
                                :value="per.id"
                              >
                                {{ per.respuesta }}
                              </option>
                            </select>
                            <label for="aprendizaje"
                              >Capacidad de aprendizaje</label
                            >
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-floating mb-7">
                            <select
                              class="form-select"
                              id="puntualidad"
                              v-model="forma.puntualidad"
                            >
                              <option value="0">:: Seleccionar ::</option>
                              <option
                                v-for="per in puntualidad"
                                :key="per.id"
                                :value="per.id"
                              >
                                {{ per.respuesta }}
                              </option>
                            </select>
                            <label for="puntualidad"
                              >Puntualidad y asistencia</label
                            >
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-floating mb-7">
                            <select
                              class="form-select"
                              id="sugerido"
                              v-model="forma.sugerido"
                            >
                              <option value="0">:: Seleccionar ::</option>
                              <option
                                v-for="per in sugeridos"
                                :key="per.id"
                                :value="per.id"
                              >
                                {{ per.respuesta }}
                              </option>
                            </select>
                            <label for="sugerido">Perfil sugerido</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            @click="handleGuardar"
          >
            GUARDAR
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- MODAL REFERENCIAS PERSONALES -->

  <div class="modal fade" tabindex="-1" id="modalPersonales">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">Referencias Personales</h3>

          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-1"></span>
          </div>
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control form-referencias-personales"
                  v-model="formaRefPer.nombre"
                  id="forma_refper_nombre"
                />
                <label for="forma_refper_nombre">Nombre completo</label>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control form-referencias-personales"
                  v-model="formaRefPer.telefono"
                  id="forma_refper_telefono"
                />
                <label for="forma_refper_telefono">Teléfono</label>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control form-referencias-personales"
                  v-model="formaRefPer.domicilio"
                  id="forma_refper_domicilio"
                />
                <label for="forma_refper_domicilio">Domicilio</label>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control form-referencias-personales"
                  v-model="formaRefPer.parentesco"
                  id="forma_refper_parentesco"
                />
                <label for="forma_refper_parentesco">Parentesco</label>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light btn-sm"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
          <button
            type="button"
            class="btn btn-primary btn-sm"
            @click="handleSaveRefPer"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- TERMINA REFERENCIAS PERSONALES -->

  <!-- MODAL REFERENCIAS LABORALES -->

  <div class="modal fade" tabindex="-1" id="modalLaborales">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">Referencias Laborales</h3>

          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-1"></span>
          </div>
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control form-referencias-laborales"
                  v-model="formaRefLab.nombre"
                  id="forma_reflab_nombre"
                />
                <label for="forma_reflab_nombre">Nombre completo</label>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control form-referencias-laborales"
                  v-model="formaRefLab.telefono"
                  id="forma_reflab_telefono"
                />
                <label for="forma_reflab_telefono">Teléfono</label>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control form-referencias-laborales"
                  v-model="formaRefLab.domicilio"
                  id="forma_reflab_domicilio"
                />
                <label for="forma_reflab_domicilio">Domicilio</label>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control form-referencias-laborales"
                  v-model="formaRefLab.empresa"
                  id="forma_reflab_empresa"
                />
                <label for="forma_reflab_empresa">Empresa</label>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light btn-sm"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
          <button
            type="button"
            class="btn btn-primary btn-sm"
            @click="handleSaveRefLab"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- TERMINA REFERENCIAS PERSONALES -->

  <!-- MODAL EXPERIENCIA-->

  <div class="modal fade" tabindex="-1" id="modalExperiencia">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">Experiencia Laboral</h3>

          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-1"></span>
          </div>
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control form-experiencia"
                  v-model="formaExp.empresa"
                  id="forma_exp_empresa"
                />
                <label for="forma_exp_empresa">Empresa</label>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control form-experiencia"
                  v-model="formaExp.tiempo"
                  id="forma_exp_tiempo"
                />
                <label for="forma_exp_tiempo">Tiempo laborado</label>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control form-experiencia"
                  v-model="formaExp.actividades"
                  id="forma_exp_actividades"
                />
                <label for="forma_exp_actividades">Actividades</label>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light btn-sm"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
          <button
            type="button"
            class="btn btn-primary btn-sm"
            @click="handleSaveExp"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- TERMINA REFERENCIAS PERSONALES -->
</template>

<script>
import { ref, render } from "vue";
import useTemplate from "../../template/composables/useTemplate";
import useEmpleados from "../composables/useEmpleados";
import { v4 as uuidv4 } from "uuid";
import { useRouter } from "vue-router";
export default {
  props: ["cid"],
  setup(props) {
    const {
      generos,
      vive,
      civiles,
      puestos,
      escolaridades,
      medios,
      ejecutivos,
      documentos,
      cuestionario,
      personalidad,
      aprendizaje,
      puntualidad,
      sugeridos,
      getCatalogos,
    } = useTemplate();
    getCatalogos();
    const {
      formaIni,
      formaRefLabIni,
      formaRefPerIni,
      formaExpIni,
      formaDocumentoIni,
      guardarEmpleado,
      getEmpleadoInfo,
      getEmpleados,
      formaBusqueda,
    } = useEmpleados();
    const forma = ref(formaIni);
    const formaRefLab = ref(formaRefLabIni);
    const formaRefPer = ref(formaRefPerIni);
    const formaExp = ref(formaExpIni);
    const formaDocumento = ref();
    const listaDocumentos = ref([]);
    const renderDoc = ref(1);
    const empFoto = ref("");

    const router = useRouter();

    if (props.cid) {
      getEmpleadoInfo(props.cid).then((result) => {
        forma.value = { ...result.info };
        listaDocumentos.value = [...forma.value.documentos];
        empFoto.value = forma.value.foto;
        console.log(empFoto.value);
      });
    } else {
      forma.value.documentos = [...listaDocumentos.value];
      empFoto.value = "/assets/media/avatars/blank.png";
    }

    const handleGuardar = () => {
      $(".required").removeClass("is-invalid");

      var flag = true;
      if (forma.value.nombre.length <= 0) {
        $("#nombre").addClass("is-invalid");
        flag = false;
      }

      if (forma.value.paterno.length <= 0) {
        $("#paterno").addClass("is-invalid");
        flag = false;
      }

      guardarEmpleado({ ...forma.value }).then(async (result) => {
        if (result.ok) {
          Swal.fire(
            "Éxito",
            "Se ha guardado la información correctamente",
            "success"
          );

          await getEmpleados(formaBusqueda.value);

          return router.replace({ name: "empleados" });
        } else {
          Swal.fire("Error", result.message, "error");
        }
      });
    };

    const handleAgregarRefPer = () => {
      formaRefPer.value = { ...formaRefPerIni };
      $(".form-referencias-personales").removeClass("is-valid");
      $(".form-referencias-personales").removeClass("is-invalid");
      $("#modalPersonales").modal("show");
    };

    const handleSaveRefPer = () => {
      var valid = true;
      if (formaRefPer.value.nombre.length <= 0) {
        $("#forma_refper_nombre").addClass("is-invalid");
        valid = false;
      } else {
        $("#forma_refper_nombre")
          .removeClass("is-invalid")
          .addClass("is-valid");
      }

      if (formaRefPer.value.telefono.length <= 0) {
        $("#forma_refper_telefono").addClass("is-invalid");
        valid = false;
      } else {
        $("#forma_refper_telefono")
          .removeClass("is-invalid")
          .addClass("is-valid");
      }

      if (formaRefPer.value.domicilio.length <= 0) {
        $("#forma_refper_domicilio").addClass("is-invalid");
        valid = false;
      } else {
        $("#forma_refper_domicilio")
          .removeClass("is-invalid")
          .addClass("is-valid");
      }

      if (formaRefPer.value.parentesco.length <= 0) {
        $("#forma_refper_parentesco").addClass("is-invalid");
        valid = false;
      } else {
        $("#forma_refper_parentesco")
          .removeClass("is-invalid")
          .addClass("is-valid");
      }

      if (valid) {
        if (formaRefPer.value.id.length <= 0) {
          formaRefPer.value.id = uuidv4();
          forma.value.referenciasPersonales.push(formaRefPer.value);
        } else {
          const index = forma.value.referenciasPersonales.findIndex(
            (item) => item.id === formaRefPer.value.id
          );
          forma.value.referenciasPersonales[index] = { ...formaRefPer.value };
        }
        $("#modalPersonales").modal("hide");
      }
    };

    const handleQuitarReferenciaPersonal = (uuid) => {
      forma.value.referenciasPersonales =
        forma.value.referenciasPersonales.filter((item) => item.id !== uuid);
    };

    const handleEditarReferenciaPersonal = (uuid) => {
      formaRefPer.value = {
        ...forma.value.referenciasPersonales.find((item) => item.id === uuid),
      };
      $(".form-referencias-personales").removeClass("is-valid");
      $(".form-referencias-personales").removeClass("is-invalid");
      $("#modalPersonales").modal("show");
    };

    const handleAgregarRefLab = () => {
      formaRefLab.value = { ...formaRefLabIni };
      $(".form-referencias-laborales").removeClass("is-valid");
      $(".form-referencias-laborales").removeClass("is-invalid");
      $("#modalLaborales").modal("show");
    };

    const handleSaveRefLab = () => {
      var valid = true;
      if (formaRefLab.value.nombre.length <= 0) {
        $("#forma_reflab_nombre").addClass("is-invalid");
        valid = false;
      } else {
        $("#forma_reflab_nombre")
          .removeClass("is-invalid")
          .addClass("is-valid");
      }

      if (formaRefLab.value.telefono.length <= 0) {
        $("#forma_reflab_telefono").addClass("is-invalid");
        valid = false;
      } else {
        $("#forma_reflab_telefono")
          .removeClass("is-invalid")
          .addClass("is-valid");
      }

      if (formaRefLab.value.domicilio.length <= 0) {
        $("#forma_reflab_domicilio").addClass("is-invalid");
        valid = false;
      } else {
        $("#forma_reflab_domicilio")
          .removeClass("is-invalid")
          .addClass("is-valid");
      }

      if (formaRefLab.value.empresa.length <= 0) {
        $("#forma_reflab_empresa").addClass("is-invalid");
        valid = false;
      } else {
        $("#forma_reflab_empresa")
          .removeClass("is-invalid")
          .addClass("is-valid");
      }

      if (valid) {
        if (formaRefLab.value.id.length <= 0) {
          formaRefLab.value.id = uuidv4();
          forma.value.referenciasLaborales.push(formaRefLab.value);
        } else {
          const index = forma.value.referenciasLaborales.findIndex(
            (item) => item.id === formaRefLab.value.id
          );
          forma.value.referenciasLaborales[index] = { ...formaRefLab.value };
        }
        $("#modalLaborales").modal("hide");
      }
    };

    const handleQuitarReferenciaLaboral = (uuid) => {
      forma.value.referenciasLaborales =
        forma.value.referenciasLaborales.filter((item) => item.id !== uuid);
    };

    const handleEditarReferenciaLaboral = (uuid) => {
      formaRefLab.value = {
        ...forma.value.referenciasLaborales.find((item) => item.id === uuid),
      };
      $(".form-referencias-laborales").removeClass("is-valid");
      $(".form-referencias-laborales").removeClass("is-invalid");
      $("#modalLaborales").modal("show");
    };

    const handleAgregarExp = () => {
      formaExp.value = { ...formaExpIni };
      $(".form-experiencia").removeClass("is-valid");
      $(".form-experiencia").removeClass("is-invalid");
      $("#modalExperiencia").modal("show");
    };

    const handleSaveExp = () => {
      var valid = true;
      if (formaExp.value.empresa.length <= 0) {
        $("#forma_exp_empresa").addClass("is-invalid");
        valid = false;
      } else {
        $("#forma_exp_empresa").removeClass("is-invalid").addClass("is-valid");
      }

      if (formaExp.value.tiempo.length <= 0) {
        $("#forma_exp_tiempo").addClass("is-invalid");
        valid = false;
      } else {
        $("#forma_exp_tiempo").removeClass("is-invalid").addClass("is-valid");
      }

      if (formaExp.value.actividades.length <= 0) {
        $("#forma_exp_actividades").addClass("is-invalid");
        valid = false;
      } else {
        $("#forma_exp_actividades")
          .removeClass("is-invalid")
          .addClass("is-valid");
      }

      if (valid) {
        if (formaExp.value.id.length <= 0) {
          formaExp.value.id = uuidv4();
          forma.value.experiencia.push(formaExp.value);
        } else {
          const index = forma.value.experiencia.findIndex(
            (item) => item.id === formaExp.value.id
          );
          forma.value.experiencia[index] = { ...formaExp.value };
        }
        $("#modalExperiencia").modal("hide");
      }
    };

    const handleQuitarExperiencia = (uuid) => {
      forma.value.experiencia = forma.value.experiencia.filter(
        (item) => item.id !== uuid
      );
    };

    const handleEditarExperiencia = (uuid) => {
      formaExp.value = {
        ...forma.value.experiencia.find((item) => item.id === uuid),
      };
      $(".form-experiencia").removeClass("is-valid");
      $(".form-experiencia").removeClass("is-invalid");
      $("#modalExperiencia").modal("show");
    };

    const handleSubirDocumento = (id) => {
      formaDocumento.value = { ...formaDocumentoIni, documento_id: id };
      document.querySelector("#documento").click();
    };

    const handleCambiaDocumento = (event) => {
      const file = event.target.files[0];
      if (file) {
        formaDocumento.value = {
          ...formaDocumento.value,
          nombre: file.name,
          archivo: file,
        };

        if (formaDocumento.value.id.length <= 0) {
          formaDocumento.value.id = uuidv4();
        }

        const indexDoc = listaDocumentos.value.findIndex(
          (item) => item.documento_id == formaDocumento.value.documento_id
        );

        if (indexDoc !== -1) {
          listaDocumentos.value[indexDoc] = { ...formaDocumento.value };
        } else {
          listaDocumentos.value.push({ ...formaDocumento.value });
        }

        forma.value.documentos = [...listaDocumentos.value];
      } else {
        formaDocumento.value = { ...formaDocumentoIni };
      }

      renderDoc.value = renderDoc.value + 1;
    };

    const handleChangeFoto = (event) => {
      const file = event.target.files[0];
      if (file) {
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.onload = () => {
          empFoto.value = fr.result;
        };
        forma.value.imagen = file;
      }
    };

    return {
      cuestionario,
      generos,
      forma,
      vive,
      civiles,
      puestos,
      escolaridades,
      medios,
      documentos,
      ejecutivos,
      formaRefLab,
      formaRefPer,
      formaExp,
      renderDoc,
      formaDocumento,
      listaDocumentos,
      cuestionario,
      personalidad,
      aprendizaje,
      puntualidad,
      sugeridos,
      empFoto,
      handleGuardar,
      handleAgregarRefPer,
      handleSaveRefPer,
      handleQuitarReferenciaPersonal,
      handleEditarReferenciaPersonal,
      handleAgregarRefLab,
      handleSaveRefLab,
      handleQuitarReferenciaLaboral,
      handleEditarReferenciaLaboral,
      handleAgregarExp,
      handleSaveExp,
      handleQuitarExperiencia,
      handleEditarExperiencia,
      handleSubirDocumento,
      handleCambiaDocumento,
      handleChangeFoto,
    };
  },
};
</script>

<style scoped>
</style>